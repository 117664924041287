import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";

import NewsBG from "Assets/newsheader.png";
import BG_Texture from 'Assets/newscontent_bg.png'
import PWIButtonIconDefault from "Assets/Button_default_341px.svg";
import PWIButtonIcon from "Assets/Button_341px.svg";
import PWIBtnSmallerDefault from "Assets/PWI_btn_Icon_default_303.svg";
import PWIBtnSmaller from "Assets/PWI_Icon_btn_303.svg";
import FrameLarge from "Assets/News/FrameLarge.png";

export const NewsDetailsWrapper = styled.div.attrs({
  className: 'NewsDetailsWrapper',
})`
  background-color: #d8cec6;
  max-width: 100vw;
  overflow-x: scroll;
  .bg-image {
    background: url(${NewsBG});
    background-position: center center;
    background-size: cover;
    height: 100%;
    min-height: 550px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${responsive.LAPTOP`
            min-height: 400px;
        `}
    ${responsive.TABLET`
            min-height: 350px;
        `}
        ${responsive.PHABLET`
            min-height: 200px;
        `}
  }
  .news-back-to-top {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .back-to-top {
    cursor: pointer;
    margin: 12px;
    text-align: center;
  }
`;

export const NewsDetailsBodyWrapper = styled.div.attrs({
  className: 'NewsDetailsBodyWrapper',
})`
  background: url("${BG_Texture}");
  background-position: center top;
  background-size: 100%;
  background-repeat: repeat-y;
  padding: 0 28px;

  ${responsive.TABLET`
    background-size: cover;
  `}

  .news-details-header-container {
    width: 100%;
    margin: -400px 0 0 0;

    ${responsive.LAPTOP`
            margin: -280px 0 0 0;
        `}
    ${responsive.TABLET`
        margin: -200px 0 0 0;
    `}
    ${responsive.PHABLET`
        margin: -100px 0 0 0;
    `}

    .border-container {
      background-image: url(${FrameLarge});
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      width: calc(100% + 26px);
      height: calc(100% + 20px);
      top: -10px;
      left: -13px;

      ${responsive.LAPTOP`
        width: calc(100% + 20px);
        height: calc(100% + 16px);
        top: -8px;
        left: -10px;
      `}

      ${responsive.TABLET`
        width: calc(100% + 10px);
        height: calc(100% + 8px);
        top: -4px;
        left: -5px;
      `}

      ${responsive.PHABLET`
        width: calc(100% + 8px);
        height: calc(100% + 6px);
        top: -3px;
        left: -4px;
      `}
    }

    .news-details-header-image {
      width: 100%;
      position: relative;

      .news-details-image {
        width: 100%;
        aspect-ratio: 16 / 9;
        height: auto;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      }
    }
  }

  .news-details-body-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .news-body-content {
      width: 100%;
      z-index: 1;
      margin: 0 auto;
      .body-sub-header-container {
        .body-sub-header {
          cursor: pointer;
          margin-top: 32px;
          margin-bottom: 32px;
          padding-left: 24px;
          padding-right: 24px;
          border-left: 2px solid #bca87a;
          text-transform: uppercase;
          letter-spacing: 2px;
          &:first-child {
            border: none;
            padding-left: 0;
          }
          &:active,
          &:hover {
            span {
              /* color: #2D448A; */
              border-bottom: 1px solid;
            }
          }
        }
      }

      /* .body-sub-header-select {
                margin: 10px;
                max-width: 280px;
            } */

      .news-body-title-container {
        .news-body-title {
          font-size: 48px;
          font-weight: bold;
          line-height: 60px;
          letter-spacing: 4px;
          color: #000;
          text-transform: uppercase;
          margin: 0;
          ${responsive.TABLET`
                        font-size: 32px;
                        line-height: 36px;
                    `}

          ${responsive.PHABLET`
                        font-size: 24px;
                        line-height: 26px;
                    `}
        }
        .news-body-sub-title {
          padding-top: 16px;
          padding-bottom: 16px;
          font-size: 32px;
          line-height: 38px;
          margin: 0;
          color: ${COLORS.SECONDARY} ${responsive.TABLET`
                        font-size: 24px;
                        line-height: 26px;

                    `} ${responsive.PHABLET`
                        font-size: 18px;
                        line-height: 20px;

                    `};
        }
      }

      .form-control-container {
        width: 100%;
      }

      .body-sub-header-select {
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-left: -0.1rem;
      }

      .news-body-text-container {
        flex-direction: column;
        padding-bottom: 24px;

        .news-body {
          width: 100%;
          font-size: 24px;
          line-height: 32px;
          table {
            max-width: 100%;
          }
          iframe {
            max-width: 100%;
            height: auto !important;
          }
          ${responsive.TABLET`
                        font-size: 20px;
                        line-height: 28px;
                        table {
                          width: 100%;
                          table-layout: fixed;
                          word-break: break-word;
                          * {
                            width: auto !important;
                            height: auto !important;
                            max-width: 100%;
                          }
                        }
                    `}
          ${responsive.PHABLET`
                        font-size: 16px;
                        line-height: 20px;
                    `}

                    img {
            max-width: 100% !important;
            height: auto;
          }

          h5 {
            font-size: 32px;
            font-weight: bold;
            color: #bca87a;
            text-transform: uppercase;
            letter-spacing: 2px;

            ${responsive.TABLET`
                            font-size: 24px;
                            line-height: 30px;
    
                        `}
            ${responsive.PHABLET`
                            font-size: 20px;
                            line-height: 26px;
    
                        `}
          }

          :is(ul, ol) {
            list-style-position: inside;
            list-style-type: revert;
          }
          li {
              list-style-type: revert;
          }
        }
      }
    }
  }

  .news-details-footer-container {
    padding-bottom: 40px;

    .news-details-footer-content {
      flex-direction: column;
      width: 100%;
      max-width: 1200px;
      margin: auto;
      ${responsive.TABLET`
                width: 100%;
            `}
      /* border-bottom: 3px solid #BCA87A;
            border-right: 3px solid #BCA87A; */
            animation: fadeInAnimation ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      .social-media-container {
        width: 100%;
      }
      .social-media-container {
        gap: 2rem;
      }
      .PWI-btn-class {
        background-image: url("${PWIButtonIconDefault}");
        background-repeat: no-repeat;
        transition: 300ms ease-in-out all;
        position: relative;
        border: none;
        width: 396px;
        height: 42px;
        background-color: transparent;
        color: white;
        cursor: pointer;
        &:hover {
          transform: translateY(-3px);
          background-image: url("${PWIButtonIcon}");
        }
        // Preload images and prevent the 'flash' on first hover
        &:after {
            position: absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
            content: url("${PWIButtonIcon}"); // load images
        }
      }

      .PWI-btn-smaller-class {
        background-image: url("${PWIBtnSmallerDefault}");
        background-repeat: no-repeat;
        transition: 300ms ease-in-out all;
        position: relative;
        border: none;
        width: 305px;
        height: 60px;
        background-color: transparent;
        color: white;
        span {
          font-size: 0.8rem;
        }
        &:hover {
          transform: translateY(-3px);
          background-image: url("${PWIBtnSmaller}");
        }
        // Preload images and prevent the 'flash' on first hover
        &:after {
            position: absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
            content: url("${PWIBtnSmaller}"); // load images
        }
      }
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .discuss-button {
      margin-bottom: 35px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      font-family: ${FONTS.SECONDARY};
      background-color: ${COLORS.SECONDARY};
      /* width: 300px; */
      text-transform: uppercase;
      letter-spacing: 4px;
      padding-right: 24px;
      padding-left: 24px;
      &:hover {
        background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
      }

      ${responsive.TABLET`
                font-size: 16px;
            `}
      ${responsive.PHABLET`
                margin : 20px 0;
                font-size: 14px;
            `}
    }

    .news-details-footer-links {
      /* width: 80%; */
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: 4px;
      padding-right: 8px;
      padding-top: 48px;
      padding-bottom: 48px;
      img {
        /* padding-left: 8px; */
        /* padding-right: 8px; */
      }
    }
  }

  .related-news-container {
    /* background-color: white; */
    flex-direction: column;

    .footer-divider {
      max-width: 100%;
    }

    .related-news-content-container {
      width: 100%;
      margin-bottom: 2rem;
      ${responsive.TABLET`
        width: 100%;
      `}

      .related-news-cards {
        flex-direction: column;
      }
      .view-more-button {
        margin: 35px 0;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        font-family: ${FONTS.SECONDARY};
        background-color: ${COLORS.SECONDARY};
        width: 300px;
        height: 50px;
        text-transform: uppercase;
        letter-spacing: 4px;

        &:hover {
          background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
        }

        ${responsive.TABLET`
                    width : 250px;
                    font-size: 16px;
                `}

        ${responsive.PHABLET`
                    margin : 20px 0;
                    font-size: 14px;
                `}
      }
    }
    .related-news-header {
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 4px;
      text-transform: uppercase;
      gap: 15px;
      margin-top: 50px;
      position: relative;
      ${responsive.TABLET`
        padding: 0;
        margin-bottom: 20px;
      `}
    }
    .related-news-loading {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      ${responsive.PHABLET`
        display: none;
      `}
    }
    .carousel-container {
      /* width: 80%; */
      /* overflow-x: visible; */
    }

    .carousel-button {
      &:hover {
        background: none;
      }
    }

    .related-news-content {
      width: 90%;
    }
  }
`;

export const RelatedNewsWrapper = styled.div.attrs({
  className: 'RelatedNewsWrapper',
})`
  padding: 24px;

  ${responsive.PHABLET`
    padding: 0;
    padding-top: 16px;
    padding-bottom: 16px;
  `}

  .related-news-image {
    margin-right: 24px;
    width: 50%;
    transition: 1 50s ease-in;
    max-width: 438px;
    max-height: 246px;
    margin-right: 16px;

    margin: 7px;

    ${responsive.PHABLET`
      width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    `}

    .container {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      width: 263px;
      height: 146px;
      margin: 10px;

      ${responsive.PHABLET`
        width: 316px;
        height: 178px;
      `}
    }
  }

  .related-news-text-container {
    flex-direction: column;
    width: 50%;

    .related-news-title {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .related-news-text {
      font-size: 14px;
      line-height: 20px;
    }
  }

  ${responsive.LAPTOP`
        flex-direction: column

        .related-news-image {
            width: 100%;
        }

        .related-news-text-container {
            width: 100%;

        }   
    `}
`;
